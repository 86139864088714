import Image from 'next/image';

interface Props {
  image: {
    imgSrc: string;
    title: string;
    caption: string;
    date: string;
  };
}

function ImageCard(props: Readonly<Props>) {
  const { image } = props;
  return (
    <>
      <Image
        fill
        priority
        alt={image.title}
        className="absolute inset-0 -z-10 h-full w-full object-cover"
        quality={90}
        sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
        src={image.imgSrc}
      />
      <div className="absolute inset-0 -z-10 flex items-end bg-gradient-to-t from-gray-900 via-gray-500/10">
        <div className="p-4">
          <div className="flex flex-wrap items-center gap-y-1 overflow-hidden leading-6 text-gray-200 md:text-base">
            <time className="mr-8 text-lg" dateTime={image.date}>
              {image.date}
            </time>
          </div>
          <h3 className="mt-3 font-semibold leading-6 text-white md:text-3xl">
            <span className="absolute inset-0 z-30" />
            {image.title}
          </h3>
          <p className="text-lg text-gray-200">{image.caption}</p>
        </div>
      </div>
    </>
  );
}

export default ImageCard;
